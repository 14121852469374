<template>
    <div>
        <st-data-table
            :items="documentSeries"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            hover
            @edit="onEdit"
        >
        </st-data-table>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DocumentSeriesModel } from './models/document-series-model';

const { fields } = DocumentSeriesModel;

export default {
    name: 'DocumentSeriesTable',
    data() {
        return {
            presenter: DocumentSeriesModel.presenter,
            fields: [
                fields.name,
                fields.prefix,
                fields.category,
                fields.current_index,
                fields.yearly_reset.extendField({ booleanType: true }),
                fields.active.extendField({ booleanType: true }),
            ],
        }
    },
    computed: {
        ...mapGetters({
            documentSeries: 'administrator/documentSeries/documentSeries',
            orgPermissions: 'administrator/orgList/orgPermissions',
        }),
        actions() {
            const actions = [];
            if (this.orgPermissions.hasPermissionToEditDocumentSeries) {
                actions.push({
                    name: 'edit',
                    icon: 'edit',
                    tooltipText: this.$t('DOCUMENT_SERIES.LIST.EDIT'),
                    customIcon: false,
                    type: 'primary'
                })
            }
            return actions
        }
    },
    methods: {
        onEdit(item) {
            this.$emit('edit', item.item);
        },
    },
}
</script>
