import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import GenericModel from '@/shared/models/generic-model';
import IntegerField from '@/shared/fields/integer-field';
import BooleanField from '@/shared/fields/boolean-field';
import EnumField from '@/shared/fields/enum-field';
import { DocumentSeriesTypeField } from './document-series-type-field';

const field = (name) => i18n.t(`DOCUMENT_SERIES.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`DOCUMENT_SERIES.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    series_type: DocumentSeriesTypeField.relationToOne(
        'type_id',
        field('series_type'),
        { autocompleteRequired: true },
        {},
        [],
        { asyncData: true }
    ),
    name: new StringField(
        'name',
        field('name'),
        { required: true },
        {
            placeholder: placeholder('name'),
        },
    ),
    description: new StringField(
        'description',
        field('description'),
        { required: true },
    ),
    prefix: new StringField(
        'prefix',
        field('prefix'),
        { required: true },
    ),
    rejected_prefix: new StringField(
        'rejected_prefix',
        field('rejected_prefix'),
        { required: false },
    ),
    separator: new EnumField(
        'separator',
        field('separator'),
        [
            {
                value: '/',
                name: '/',
                text: '/',
                type: 'primary',
            },
            {
                value: '-',
                name: '-',
                text: '-',
                type: 'primary',
            },
            {
                value: '_',
                name: '_',
                text: '_',
                type: 'primary',
            }
        ],
        { autocompleteRequired: true },
    ),
    category: new StringField(
        'category',
        field('category'),
        { required: false },
    ),
    current_index: new IntegerField(
        'current_index',
        field('current_index'),
        { required: true, min: 1 },
    ),
    rejected_index: new IntegerField(
        'rejected_index',
        field('rejected_index'),
        { required: false, min: 1 },
    ),
    yearly_reset: new BooleanField(
        'yearly_reset',
        field('yearly_reset'),
        { required: true },
    ),
    active: new BooleanField(
        'active',
        field('active'),
        { required: true },
    ),
}

export class DocumentSeriesModel extends GenericModel {
    static get fields() {
        return fields;
    }
};
